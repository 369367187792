<template>
  <div>
    <CSBreadcrumb v-if="previousRoute.name == 'propertyData'" />
    <div class="filter-panel">
      <input
        type="text"
        class="cs-input"
        placeholder="搜索姓名/手机号/地址"
        style="margin-left: 0"
        v-model="queryParams.search"
      />
      <CSSelect style="margin-right: 20px">
        <select v-model="queryParams.gender">
          <option value="">性别不限</option>
          <option :value="1">男</option>
          <option :value="2">女</option>
        </select>
      </CSSelect>
      <CSSelect style="margin-right: 20px">
        <select v-model="queryParams.departmentCode">
          <option value="">全部部门</option>
          <option
            v-for="department in queryParamsDepartmentList"
            :key="department.code"
            :value="department.code"
          >
            {{ department.name }}
          </option>
        </select>
      </CSSelect>
      <CSSelect style="margin-right: 20px">
        <select v-model="queryParams.jobLevel">
          <option value="">全部职位</option>
          <option
            v-for="(name, level) in jobLevelStr"
            :key="level"
            :value="level"
          >
            {{ name }}
          </option>
        </select>
      </CSSelect>
      <CSSelect>
        <select v-model="queryParams.isEnable">
          <option value="">在职状态不限</option>
          <option :value="1">在职</option>
          <option :value="0">离职</option>
        </select>
      </CSSelect>
      <button class="btn btn-primary ml-4" @click="getStaffList()">查询</button>
    </div>
    <div class="result-panel">
      <CSTable :thead-top="filterHeight">
        <template v-slot:header v-if="$route.params.return != 1">
          <div class="table-header-panel text-right">
            <button
              class="btn btn-primary sticky-right"
              @click="openAddOrEditStaffModal()"
            >
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-menujiahao"></use></svg
              >添加员工
            </button>
          </div>
        </template>
        <template v-slot:thead>
          <tr>
            <th class="text-center">姓名</th>
            <th class="text-center">性别</th>
            <th class="text-center">手机号</th>
            <th class="text-center">地址</th>
            <th class="text-center">部门</th>
            <th class="text-center">岗位</th>
            <th class="text-center">照片</th>
            <th class="text-center">门卡编号</th>
            <!--                    <th class="text-center">门卡使用范围</th>-->
            <th class="text-center">在职状态</th>
            <th class="text-center">操作</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <tr
            v-for="staff in staffList"
            :key="staff.id"
            v-if="
              staff.name !== '超级管理员' || staff.id === currentAccountInfo.id
            "
          >
            <td class="text-center">{{ staff.name }}</td>
            <td class="text-center">
              <template v-if="[1, 2].includes(staff.gender)">
                {{ staff.gender == 1 ? "男" : "女" }}
              </template>
              <template v-else>-</template>
            </td>
            <td class="text-center">{{ staff.phone }}</td>
            <td class="text-center">{{ staff.address }}</td>
            <td class="text-center">
              {{ staff.departmentName || "-" }}
            </td>
            <td class="text-center">
              {{ staff.jobTitle || "-" }}
            </td>
            <!-- <td class="text-center">{{ staff.email }}</td> -->

            <td class="text-center">
              <span
                class="allow-click"
                v-if="staff.facePhoto"
                @click="lookStaffFaceImg(staff.id)"
                >查看</span
              >
              <template v-else>-</template>
            </td>
            <td class="text-center">
              {{ staff.cordNo || "-" }}
            </td>
            <!--                            <td class="text-center">
                                        <span class="allow-click" v-if="staff.deviceGroupInfo" @click="lookDeviceGroupDetail(staff.groupId)">
                                          {{staff.deviceGroupInfo}}
                                        </span>
                                                    <template v-else>
                                                        -
                                                    </template>

                                                </td>-->
            <td class="text-center">
              {{
                !staff.resignTime && !Number(staff.resignBy) ? "在职" : "离职"
              }}
            </td>
            <td class="text-center">
              <div class="btn-group">
                <button
                  type="button"
                  class="btn btn-primary dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  操作
                </button>
                <ul class="dropdown-menu">
                  <li
                    v-if="!staff.resignTime && !staff.resignBy && !staff.cordNo"
                    @click="accessCardDialog = { id: staff.id, visible: true }"
                  >
                    <a style="width: 100%">发门卡</a>
                  </li>
                  <li v-if="staff.cordNo" @click="recycleCard(staff.id)">
                    <a style="width: 100%">回收门卡</a>
                  </li>
                  <li>
                    <a
                      @click="openUploadStaffFaceImg(staff.id)"
                      style="width: 100%"
                      >上传照片</a
                    >
                  </li>
                  <li>
                    <a
                      @click="openAddOrEditStaffModal(staff)"
                      style="width: 100%"
                      >修改</a
                    >
                  </li>
                  <li v-if="!staff.resignTime && !Number(staff.resignBy)">
                    <a @click="openResignStaffModal(staff)" style="width: 100%"
                      >离职</a
                    >
                  </li>
                  <li v-else>
                    <a @click="openEntryStaffModal(staff)" style="width: 100%"
                    >入职</a
                    >
                  </li>
                  <li>
                    <a
                      @click="openRoleBindingResourceDialog(staff)"
                      style="width: 100%"
                      >设置权限组</a
                    >
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </template>
      </CSTable>
      <Pagination name="Pagination" component="Pagination"></Pagination>
    </div>

    <ViewImageModal />

    <!-- 添加、编辑员工 -->
    <CSDialog
      :dialog-visible="addOrEditModalVisible"
      :dialog-title="`${addOrEditParams.id ? '编辑' : '添加'}员工`"
      @onClose="addOrEditModalVisible = false"
      @onConfirm="commitAddOrEditStaff"
      dialogWidth="850px"
    >
      <template v-slot:dialog-content>
        <div style="padding: 30px">
          <div class="form-panel">
            <div style="font-size: 24px; margin-bottom: 23px">
              <div
                style="
                  display: inline-block;
                  width: 100px;
                  text-align: right;
                  margin-right: 40px;
                "
              >
                公司
              </div>
              <CSSelect iWidth="36px" style="margin-right: 20px" height="40px">
                <select
                  v-model="addOrEditParams.companyCode"
                  @change="changeCompany"
                  style="padding-left: 5px; max-width: 200px;color: #999999"
                >
                  <option value="">请选择</option>
                  <option
                    v-for="company in companyList"
                    :key="company.code"
                    :value="company.code"
                  >
                    {{ company.name }}
                  </option>
                </select>
              </CSSelect>
              <CSSelect
                iWidth="36px"
                height="40px"
                style="margin-right: 20px; padding-left: 6px"
              >
                <select
                  v-model="addOrEditParams.departmentCode"
                  style="max-width: 200px;color: #999999"
                  @change="changeDepartment"
                >
                  <option value="">请选择</option>
                  <option
                    v-for="department in departmentList"
                    :key="department.code"
                    :value="department.code"
                  >
                    {{ department.name }}
                  </option>
                </select>
              </CSSelect>
              <CSSelect
                iWidth="36px"
                height="40px"
                style="padding-left: 6px"
                v-if="groupList.length > 0"
              >
                <select
                  v-model="addOrEditParams.groupCode"
                  style="max-width: 200px;color: #999999"
                >
                  <option value="">请选择</option>
                  <option
                    v-for="group in groupList"
                    :key="group.code"
                    :value="group.code"
                  >
                    {{ group.name }}
                  </option>
                </select>
              </CSSelect>
            </div>
            <div style="font-size: 24px; margin-bottom: 23px">
              <div
                style="
                  display: inline-block;
                  width: 100px;
                  text-align: right;
                  margin-right: 40px;
                "
              >
                姓名
              </div>
              <input
                v-model="addOrEditParams.name"
                type="text"
                placeholder="请输入"
                style="
                  border-radius: 4px;
                  border: 1px solid #999;
                  padding-left: 10px;
                  width: 400px;
                "
              />
            </div>
            <div style="font-size: 24px; margin-bottom: 23px">
              <div
                style="
                  display: inline-block;
                  width: 100px;
                  text-align: right;
                  margin-right: 40px;
                "
              >
                手机号
              </div>
              <input
                v-model="addOrEditParams.phone"
                type="text"
                maxlength="11"
                placeholder="请输入"
                style="
                  border-radius: 4px;
                  border: 1px solid #999;
                  padding-left: 10px;
                  width: 400px;
                "
              />
            </div>
            <div style="font-size: 24px; margin-bottom: 23px">
              <div
                style="
                  display: inline-block;
                  width: 100px;
                  text-align: right;
                  margin-right: 40px;
                "
              >
                性别
              </div>
              <CSSelect iWidth="36px" height="40px" style="width: 400px">
                <select
                  v-model="addOrEditParams.gender"
                  style="padding-left: 6px;color: #999999"
                >
                  <option value="">请选择</option>
                  <option :value="1">男</option>
                  <option :value="2">女</option>
                </select>
              </CSSelect>
            </div>
            <div style="font-size: 24px; margin-bottom: 23px">
              <div
                style="
                  display: inline-block;
                  width: 100px;
                  text-align: right;
                  margin-right: 40px;
                "
              >
                员工职级
              </div>
              <CSSelect iWidth="36px" height="40px" style="width: 400px">
                <select
                  v-model="addOrEditParams.jobLevel"
                  style="padding-left: 6px;color: #999999"
                >
                  <option value="">请选择</option>
                  <option
                    v-for="(name, level) in jobLevelStr"
                    :key="level"
                    :value="level"
                  >
                    {{ name }}
                  </option>
                </select>
              </CSSelect>
            </div>
            <div style="font-size: 24px; margin-bottom: 23px">
              <div
                style="
                  display: inline-block;
                  width: 100px;
                  text-align: right;
                  margin-right: 40px;
                "
              >
                职位名称
              </div>
              <input
                v-model="addOrEditParams.jobTitle"
                type="text"
                maxlength="11"
                placeholder="请输入"
                style="
                  border-radius: 4px;
                  border: 1px solid #999;
                  padding-left: 10px;
                  width: 400px;
                "
              />
            </div>
            <div style="font-size: 24px; margin-bottom: 23px">
              <div
                style="
                  display: inline-block;
                  width: 100px;
                  text-align: right;
                  margin-right: 40px;
                "
              >
                住址
              </div>
              <input
                v-model="addOrEditParams.address"
                type="text"
                placeholder="请输入"
                style="
                  border-radius: 4px;
                  border: 1px solid #999;
                  padding-left: 10px;
                  width: 400px;
                "
              />
            </div>
          </div>
        </div>
      </template>
    </CSDialog>

    <!-- 添加权限组 -->
    <CSDialog
      dialog-title="设置权限组"
      @onClose="staffBindingRoleModalVisible = false"
      :dialog-visible="staffBindingRoleModalVisible"
      @onConfirm="setupStaffRole"
    >
      <template v-slot:dialog-content>
        <div style="font-size: 24px; padding: 30px">
          <div style="max-height: 400px; overflow-y: auto">
            <CSTree :tree="roleList" @change="changeCheckedRole"></CSTree>
            <!--             <el-tree
              :data="roleList"
              :show-checkbox="true"
              node-key="id"
              :default-expanded-keys="checkedExpandedKey"
              :default-checked-keys="checkedRoleKey"
              @check-change="changeCheckedRole"
              icon-class=""
              :props="{
                label: 'name',
              }"
            >
            </el-tree>-->
          </div>
        </div>
      </template>
    </CSDialog>
    <AccessCardWrite
      :id="accessCardDialog.id"
      :visible="accessCardDialog.visible"
      @refresh="getStaffList"
      @onClose="accessCardDialog.visible = false"
      :cardType="2"
    />
    <!--    上传人脸照片弹窗    -->
    <CSDialog
      dialog-title="上传照片"
      :dialog-show-confirm-btn="false"
      dialog-width="430px"
      @onClose="uploadImgDialog.visible = false"
      :dialog-visible="uploadImgDialog.visible"
      dialog-cancel-btn-text="关闭"
    >
      <template v-slot:dialog-content>
        <div class="upload-staff-dialog-content">
          <div>
            <div
              :class="[
                'upload-photo',
                { verifyPhoto: uploadImgDialog.verify === 1 },
              ]"
              @click="triggerChoosePhoto"
              v-if="uploadImgDialog.file === ''"
            >
              <div class="text-center">
                <img
                  src="@/assets/upload.png"
                  alt=""
                  style="margin: 5px auto 5px"
                />
                <p style="color: #999">上传照片</p>
              </div>
            </div>
            <div
              v-else
              :class="[
                'photo-view',
                { verifyPhoto: uploadImgDialog.verify === 1 },
              ]"
              @click="triggerChoosePhoto"
            >
              <img :src="uploadImgDialog.file" alt="" />
            </div>
            <input
              type="file"
              class="file"
              accept="images/*"
              id="uploadStaffPhoto"
              hidden
              @change="chooseStaffPhoto($event)"
            />
          </div>
          <div class="tips">
            <svg aria-hidden="true" class="icon">
              <use xlink:href="#icon-menua-zu92"></use>
            </svg>
            请上传员工人脸照片，用于刷脸打卡，刷脸通行。
          </div>
        </div>
      </template>
    </CSDialog>
  </div>
</template>
<script>
import CSSelect from "@/components/common/CSSelect";
import CSDialog from "@/components/common/CSDialog";
import CSTree from "@/components/common/CSTree";
import Pagination from "@/components/Pagination";
import {
  JOB_LEVEL,
  ORG_LEVEL,
  DEPARTMENT_TYPES,
  STORAGE_KEY,
} from "@/constant";
import ViewImageModal from "@/components/ViewImageModal";

import {
  queryDepartmentStaffUrl,
  resignStaffUrl,
  editStaffUrl,
  queryDepartmentUrl,
  addStaffUrl,
  setupUserRoleUrl,
  queryUserRoleUrl,
  queryRoleUrl,
  uploadFaceImageUrl,
  checkFaceImgUrl,
  getFaceImgUrl,
  staffRecycleCardUrl,
  queryGroupDevices, entryUserUrl,
} from "@/requestUrl";
import CSBreadcrumb from "@/components/common/CSBreadcrumb";
import AccessCardWrite from "@/components/AccessCardWrite/AccessCardWrite";
import RightWidgetMenu from "@/components/common/RightWidgetMenu";
import CSTable from "@/components/common/CSTable";
import OSS from "ali-oss";
export default {
  name: "staff",
  created() {
    window.addEventListener("keydown", this.getStaffListDown);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.getStaffListDown);
  },
  deactivated() {
    window.removeEventListener("keydown", this.getStaffListDown);
  },
  components: {
    CSTable,
    RightWidgetMenu,
    AccessCardWrite,
    CSBreadcrumb,
    CSDialog,
    ViewImageModal,
    CSSelect,
    Pagination,
    CSTree,
  },
  data() {
    return {
      stsTokenStorage: {},
      accessCardDialog: {
        id: 0,
        visible: false,
      },
      filterHeight: 0,
      currentAccountInfo: this.$vc.getCurrentStaffInfo(),
      uploadImgDialog: {
        visible: false,
        userId: "",
        verify: 0, // 0: 未验证  1: 验证中   2:验证结束
        file: "",
      },
      JOB_LEVEL,
      ORG_LEVEL,
      DEPARTMENT_TYPES,
      jobLevelStr: {
        [JOB_LEVEL.SUPERVISOR]: "部门主管",
        [JOB_LEVEL.TEAM_SUPERVISOR]: "小组主管",
        [JOB_LEVEL.STAFF]: "员工",
        [JOB_LEVEL.TRAINEE]: "实习生",
      },
      staffList: [],
      companyList: [],
      // 筛选条件用的部门列表
      queryParamsDepartmentList: [],
      departmentList: [],
      addOrEditModalVisible: false,
      // 添加、编辑员工的参数
      addOrEditParams: {
        regionId: this.$vc.getCurrentRegion().code,
        companyCode: "",
        name: "",
        email: "",
        phone: "",
        gender: "",
        jobLevel: "",
        jobTitle: "",
        address: "",
        // photos: "",
        // -----
        id: "",
        isEnable: "",
        reginTime: "",
        reginBy: "",
        groupCode: "",
      },
      groupList: [],
      // 查询时的参数
      queryParams: {
        regionCode: this.$vc.getCurrentRegion().code || "",
        departmentCode: "",
        jobLevel: "",
        isOnTheJob: "",
        search: "",
        gender: "",
        isEnable: "",
      },
      staffBindingRoleModalVisible: false,
      roleList: [],
      // 正在进行绑定权限组的员工
      staffId: null,
      checkedRoleKey: [],
      previousRoute: {},
    };
  },
  async mounted() {
    this.$vc.on(this.$route.path, "pagination", "page_event", (currentPage) => {
      this.getStaffList(currentPage);
    });
    this.getStaffList();
    this.queryParamsDepartmentList = await this.queryDepartmentList(
      ORG_LEVEL.DEPARTMENT,
      1,
      100,
      this.currentAccountInfo.companyCode
    );
    this.previousRoute = JSON.parse(
      sessionStorage.getItem(STORAGE_KEY.ROUTE_HISTORY)
    )[0];
    this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
    window.addEventListener("resize", () => {
      this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
    });
  },
  methods: {
    getStaffListDown(e) {
      if (e.keyCode == 13) {
        this.getStaffList();
      }
    },
    /**
     * 查询面板组详情
     * @param {Number} groupId 面板组id
     * */
    lookDeviceGroupDetail(groupId) {
      this.$fly
        .get(queryGroupDevices, {
          groupId,
        })
        .then((res) => {
          if (res.code !== 0) {
            this.$vc.toast("没有范围");
            return;
          }
          const deviceType = {
            1: "闸机(进)",
            2: "闸机(出)",
            3: "会议室",
            4: "墙面广告面板",
            5: "员工考勤面板",
            6: "楼层门禁",
          };
          this.$CSDialog.alert({
            title: "门卡使用范围",
            messageHtml: `
          <ol>
            ${res.data.map((device) => {
              return `<li style="text-align: left;">${device.deviceNo}-${
                deviceType[device.type]
              }-${device.buildingName}-${device.floor}</li>`;
            })}
          </ol>
          `,
          });
        });
    },
    async lookStaffFaceImg(userId) {
      // const baseString = await this.getStaffFaceImg(userId);
      // console.log(baseString, '图片地址酒啊');
      // this.$vc.emit(this.$route.path, "viewImageModal", "view", [baseString]);
      await this.droneShootPicture(
        `${userId}/${userId}.jpg`
      );
    },
    async droneShootPicture(picture) {
      const client = await this.createOss("oss-cn-shanghai", "cosmoss-faces");
      let pictureUrl = client.signatureUrl(picture);
      this.$vc.emit(this.$route.path, "viewImageModal", "view", [pictureUrl]);
    },
    async createOss(region, bucket) {
      const { oss, stsToken } = this.stsTokenStorage;
      if (stsToken && stsToken.expireTime > new Date().getTime()) {
        return oss;
      }
      let res = await this.$fly.get("/account/staff/ossKey");
      let ossKeyData = res.data;
      const ossConfig = {
          region,
          accessKeyId: ossKeyData.accessKeyId,
          accessKeySecret: ossKeyData.accessKeySecret,
          bucket,
          stsToken: ossKeyData.token,
        },
        aliOss = new OSS(ossConfig);
      this.stsTokenStorage = {
        stsToken: ossKeyData,
        oss: aliOss,
      };
      return aliOss;
    },
    getStaffFaceImg(userId) {
      return this.$fly
        .readFileStream({
          url: getFaceImgUrl,
          data: { userId },
        })
        .then((res) => res)
        .catch(() => {
          this.$vc.message("获取图片失败");
        });
    },
    /**
     * 上传人脸照片
     * @param {Number} userId 员工的id
     * */
    openUploadStaffFaceImg(userId) {
      this.uploadImgDialog = {
        visible: true,
        verify: 0,
        userId,
        file: "",
      };
    },
    /**
     * 回收门卡
     * @param {Number} id 员工id
     * */
    recycleCard(id) {
      this.$CSDialog.confirm({
        message:
          "确定回收门卡吗？确定后，此卡将无法使用，需重新写卡才可继续使用。",
        onConfirm: () => {
          this.$fly
            .get(staffRecycleCardUrl, {
              id,
            })
            .then((res) => {
              if (res.code !== 0) {
                return;
              }
              this.$vc.toast("回收成功");
              this.$CSDialog.instance.closeDialog();
              this.getStaffList();
            });
        },
      });
    },
    // 更新员工权限组绑定
    setupStaffRole() {
      this.$fly
        .post(setupUserRoleUrl, {
          userId: this.staffId,
          roles: this.checkedRoleKey,
        })
        .then((res) => {
          if (res.code !== 0) {
            return;
          }
          this.staffBindingRoleModalVisible = false;
        });
    },
    /**
     * 更改选中权限组列表
     *
     */
    changeCheckedRole(checkBoxInfo) {
      const { key, source } = checkBoxInfo,
        checkbox = source[key];
      if (!checkbox?.children) {
        const index = this.checkedRoleKey.indexOf(checkbox.id);
        if (index > -1) {
          checkbox.checked = false;
          this.checkedRoleKey.splice(index, 1);
        } else {
          checkbox.checked = true;
          this.checkedRoleKey.push(checkbox.id);
        }
        console.log(this.checkedRoleKey);
      } else {
        this.changeChildrenCheckState(
          checkbox,
          { checkedIds: this.checkedRoleKey, roomNames },
          !checkbox.checked
        );
      }
    },
    /**
     * 获取权限组绑定的资源
     * @param {Number} staffId 员工id
     *  */
    getRoleBindingResourceList(staffId, roleList) {
      return this.$fly
        .get(queryUserRoleUrl, { userId: staffId })
        .then((res) => {
          if (res.code !== 0) {
            resolve(null);
            return;
          }
          this.checkedRoleKey = [];
          res.data.forEach((val) => {
            this.checkedRoleKey.push(val.id);
          });
          roleList.map((role) => {
            role.checked = this.checkedRoleKey.includes(role.id);
            return role;
          });
          return res.data;
        });
    },
    // 打开员工绑定权限组弹窗
    async openRoleBindingResourceDialog(staff) {
      const { id: staffId, name: staffName } = staff;
      const roleList = await this.getRoleList(staffId, staffName);
      this.staffId = staffId;
      await this.getRoleBindingResourceList(staffId, roleList);
      this.staffBindingRoleModalVisible = true;
    },
    // 获取权限组列表
    getRoleList(userId, staffName) {
      return this.$fly
        .post(queryRoleUrl, {
          name: "",
          userId,
        })
        .then((res) => {
          const checkedRoleKey = [],
            roleList = [];
          for (let role of res.data) {
            role.checked = role?.isHave;
            if (role?.isHave) {
              checkedRoleKey.push(role.id);
            }
            if (staffName !== "超级管理员" && role.name.includes("所有权限")) {
              continue;
            }
            roleList.push(role);
          }
          this.checkedRoleKey = checkedRoleKey;
          this.roleList = roleList;
          return res.data;
        });
    },
    changeCompany() {
      this.queryDepartmentList(
        ORG_LEVEL.DEPARTMENT,
        1,
        100,
        this.addOrEditParams.companyCode
      );
    },
    changeDepartment() {
      this.queryDepartmentList(
        ORG_LEVEL.GROUP,
        1,
        100,
        this.addOrEditParams.departmentCode
      );
    },
    /**
     * 查询公司/部门接口
     * @param {Number} level 0:代表公司
     * @param {Number} pageNo 分页页数
     * @param {Number} pageSize 每次查看的数量
     * @param {Number | String} parentCode 公司code
     *  */
    queryDepartmentList(
      level = ORG_LEVEL.COMPANY,
      pageNo = 1,
      pageSize = 100,
      parentCode
    ) {
      return this.$fly
        .post(queryDepartmentUrl, {
          regionCode: this.$vc.getCurrentRegion().code,
          code: "",
          name: "",
          dutyType: "",
          parentCode,
          level,
          pageNo,
          pageSize: level == ORG_LEVEL.COMPANY ? 100 : pageSize,
        })
        .then((res) => {
          if (res.code !== 0) {
            return [];
          }
          switch (+level) {
            case ORG_LEVEL.COMPANY:
              this.companyList = res.data;
              break;
            case ORG_LEVEL.DEPARTMENT:
              this.departmentList = res.data;
              break;
            case ORG_LEVEL.GROUP:
              this.groupList = res.data;
              break;
          }
          return res.data || [];
        });
    },
    triggerChoosePhoto() {
      document.getElementById("uploadStaffPhoto").click();
    },
    // 上传图片
    uploadStaffPhoto(file) {
      let formData = new FormData();
      formData.append("file", file);
      formData.append("userId", this.uploadImgDialog.userId);
      this.$fly
        .post(uploadFaceImageUrl, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(async (res) => {
          if (res.code !== 0) {
            return;
          }
          this.uploadImgDialog.verify = 1;
          const faceResult = await this.faceRec(0, this.uploadImgDialog.userId);
          this.uploadImgDialog.verify = 2;
          if (faceResult.data !== 1) {
            this.$vc.message("人脸验证失败，请上传可用照片");
          } else {
            this.$vc.toast("人脸上传成功");
            this.uploadImgDialog.visible = false;
          }
        });
    },
    /**
     * 等待时间
     * @param {Number} time 等待时间 单位毫秒
     * */
    sleepTimer(time) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(true);
        }, time);
      });
    },
    /** 人脸验证 */
    faceRec(requestCount, userId) {
      return this.$fly
        .get(checkFaceImgUrl, {
          userId,
        })
        .then(async (res) => {
          if (requestCount < 5) {
            if (res.code !== 0 || res.data !== 1) {
              await this.sleepTimer(2000);
              requestCount++;
              return this.faceRec(requestCount, userId);
            } else {
              return { data: res.data };
            }
          }
          return { data: 0 };
        })
        .catch((err) => {
          return { data: 0 };
        });
    },
    // 选择文件后处理
    async chooseStaffPhoto(event) {
      var photoFiles = event.target.files;
      if (photoFiles && photoFiles.length > 0) {
        // 获取目前上传的文件
        var file = photoFiles[0]; // 文件大小校验的动作
        this.uploadImgDialog.file = await this.fileByBase64(file);
        /*if (file.size > 1024 * 100 * 2) {
          this.$vc.toast("图片大小不能超过 200kb!");
          return false;
        }*/
        this.uploadStaffPhoto(file);
      }
    },
    fileByBase64(file) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function (e) {
          resolve(e.target.result);
        };
      });
    },
    /**
     * 打开添加、编辑员工弹窗
     * @param {Object} staff 员工信息
     *  */
    async openAddOrEditStaffModal(staff) {
      await this.queryDepartmentList();
      if (staff) {
        const departmentList = await this.queryDepartmentList(
          ORG_LEVEL.DEPARTMENT,
          1,
          100,
          staff.companyCode
        );
        const haveChildDepartments = departmentList.filter((department) =>
          department.name.includes("招商")
        );
        const groupList = await this.queryDepartmentList(
          ORG_LEVEL.GROUP,
          1,
          100,
          haveChildDepartments[0]?.code || ""
        );
        const groupCodes = groupList.map((group) => group.code);
        this.addOrEditParams = {
          regionId: this.$vc.getCurrentRegion().code,
          companyCode: "",
          name: "",
          email: "",
          phone: "",
          departmentCode: "",
          gender: "",
          jobLevel: "",
          jobTitle: "",
          address: "",
          // photos: "",
          id: "",
          groupCode: "",
          // isEnable: "",
          // reginTime: "",
          // reginBy: "",
        };
        this.$vc.copyObject(staff, this.addOrEditParams);
        // 处理有下级的部门，暂时指定招商部
        if (groupCodes.includes(staff.departmentCode)) {
          console.log(staff.departmentCode);
          this.addOrEditParams.departmentCode = haveChildDepartments[0].code;
          this.addOrEditParams.groupCode = staff.departmentCode;
        } else {
          this.groupList = [];
        }
      } else {
        this.groupList = [];
        this.addOrEditParams = {
          regionId: this.$vc.getCurrentRegion().code,
          companyCode: "",
          name: "",
          email: "",
          phone: "",
          departmentCode: "",
          gender: "",
          jobLevel: "",
          jobTitle: "",
          address: "",
          // photos: "",
          groupCode: "",
        };
      }
      this.addOrEditModalVisible = true;
    },
    // 提交员工信息
    commitAddOrEditStaff() {
      if (this.addOrEditParams.id) {
        if (this.addOrEditParams.groupCode !== "") {
          this.addOrEditParams.departmentCode = this.addOrEditParams.groupCode;
        }
        delete this.addOrEditParams.groupCode;
      }
      this.$fly
        .post(
          this.addOrEditParams.id ? editStaffUrl : addStaffUrl,
          this.addOrEditParams
        )
        .then((res) => {
          if (res.code !== 0) {
            return;
          }
          this.addOrEditModalVisible = false;
          this.$vc.toast("操作成功");
          this.getStaffList();
        });
    },
    // 获取员工列表
    getStaffList(pageNo = 1, pageSize = 10) {
      this.$fly
        .post(queryDepartmentStaffUrl, {
          // pageNo,
          // pageSize,
          ...this.queryParams,
        })
        .then((res) => {
          if (res.code !== 0) {
            return;
          }
          if (pageNo === 1) {
            this.$vc.emit(this.$route.path, "pagination", "init", {
              total: res.data.total || res.data.length,
              currentPage: pageNo,
              pageSize: res.data.total ? pageSize : res.data.length,
            });
          }
          this.staffList = res.data.total ? res.data.datas : res.data;
        });
    },
    // 打开确认员工离职弹窗
    openResignStaffModal(staff) {
      this.$CSDialog.confirm({
        title: "提示",
        message: `确定${staff.name}已经离职?`,
        onConfirm: () => {
          this.$fly
            .post(resignStaffUrl, {
              id: staff.id,
            })
            .then((res) => {
              if (res.code !== 0) {
                return;
              }
              this.$vc.toast("操作成功");
              this.$CSDialog.instance.closeDialog();
            });
        },
      });
    },
    openEntryStaffModal(staff) {
      this.$CSDialog.confirm({
        title: "提示",
        message: `确定入职吗?`,
        onConfirm: () => {
          this.$fly
                  .post(entryUserUrl, {
                    id: staff.id,
                  })
                  .then((res) => {
                    if (res.code !== 0) {
                      return;
                    }
                    this.$vc.toast("操作成功");
                    this.$CSDialog.instance.closeDialog();
                  });
        },
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.verifyPhoto-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  content: '验证中...';
  color: #fff;
  text-align: center;
  line-height: 80px;
}

.upload-staff-dialog-content {
  padding: 20px;

  .tips {
    margin-top: 20px;
    color: #999;
  }

  .upload-photo {
    position: relative;

    &.verifyPhoto {
      &::before {
        @extend .verifyPhoto-bg;
      }
    }
  }

  .photo-view {
    &.verifyPhoto {
      &::before {
        @extend .verifyPhoto-bg;
      }
    }
  }
}

.form-panel {
  .form-item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    font-size: 20px;

    &-label {
      width: 140px;
      margin-right: 20px;
    }

    input {
      border: 1px solid #979797;
      padding: 0 10px;
      border-radius: 4px;
    }

    &:not(:last-of-type) {
      margin-bottom: 20px;
    }
  }
}

/deep/ {
  label.el-checkbox {
    margin-bottom: 0;
  }

  .el-tree-node__label {
    font-size: 24px;
    line-height: 1;
  }

  .el-checkbox__inner {
    width: 24px;
    height: 24px;
  }

  .el-checkbox__inner::after {
    box-sizing: content-box;
    content: '';
    border: 1px solid #FFF;
    border-left: 0;
    border-top: 0;
    height: 12px;
    left: 6px;
    position: absolute;
    top: 2px;
    transform: rotate(45deg) scaleY(0);
    width: 8px;
    transition: transform 0.15s ease-in 0.05s;
    transform-origin: center;
  }

  .el-tree-node__content {
    min-height: 26px;
    height: 40px;
  }
}

input::-webkit-input-placeholder {
  color: #999;
}

input::-moz-placeholder {
  color: #999;
}

input::-moz-placeholder {
  color: #999;
}

input::-ms-input-placeholder {
  color: #999;
}
</style>
